.container {
	display: flex;
	flex-direction: column;
}

.state-handler {
	display: flex;
	justify-content: space-between;
}

.loading {
	z-index: 50;
	position: absolute;
	top: 0;
	left: 0;
	min-height: 100vh;
	width: 100%;
	background: rgba(1, 1, 1, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}
