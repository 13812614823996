.container {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 2.2rem;
	z-index: 7;
	background-color: white;
	border-bottom: rgb(50, 50, 50) solid 1px;
	padding-right: 1rem;
	transition: all 0.3s;
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	user-select: none;
}

@media (max-width: 991px) {
	.container {
		padding-right: 0 !important;
		left: 0 !important;
		height: 3rem !important;
		width: 100% !important;
	}
	.container:not(:not(.hide)) {
		left: -52px !important;
	}
}

.cont-show {
	position: relative;
	left: 0;
	animation: 0.3s forwards show;
	transition: all 0.2s;
}

.cont-hide {
	position: relative;
	animation: 0.3s forwards hide;
	transition: all 0.2s;
}

.menu-init {
	height: 100%;
	width: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: 0.3s forwards rot;
}

.menu-show {
	animation: 0.4s forwards arot;
}

.menu-hide {
	animation: 0.4s forwards rot;
}

@keyframes rot {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-180deg);
	}
}

@keyframes arot {
	0% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
