.container {
	position: relative;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.up {
	border-top-style: solid;
}

.left {
	left: 0;
	border-left-style: solid;
	transform-origin: top left;
}

.right {
	right: 0;
	border-right-style: solid;
	transform-origin: top right;
}

.left, .right {
	position: absolute;
	height: 100%;
}
