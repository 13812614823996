.container {
	position: relative;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	box-shadow: 0 0 1px 1px black;
}

.circle {
	position: absolute;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px black;
}
