.container {
	position: absolute;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	overflow: visible;
	/*background: rgba(255, 133, 133, 0.28); !* develop *!*/
}

.horiz-cont {
	flex-direction: column;
}
