.container {
	position: relative;
	padding-top: 2.3rem;
	width: 220px;
	background: linear-gradient(to top, #5768f3, #1c45ef);
	border-right: #1c45ef solid 1px;
	z-index: 6;
	transition: all 0.3s;
}

@media (max-width: 991px) {
	.hide {
		width: 0 !important;
		transition: all 0.3s;
	}

	.container {
		position: absolute;
		height: calc(100% - 3rem - 0.5rem) !important;
		top: 3rem !important;
		border-width: 0;
		padding-top: 0.5rem;
		overflow: hidden;
		transition: all 0.3s;
	}
}

.hide {
	width: 51px;
	transition: all 0.3s;
}

.route {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.6rem 0.5rem;
	cursor: pointer;
	overflow: hidden;
	transition: all 0.3s;
}

.route:first-of-type {
	padding-top: 0.7rem;
}

.container:not(.hide) .route {
	padding-left: 0.9rem;
	transition: all 0.3s;
}

.hide .route:hover, .container:not(.hide) .route:hover {
	background-color: #0f25d5;
	transition: all 0.3s;
}

.container:not(.hide) .route:hover {
	padding-right: 0.2rem;
	padding-left: 1.2rem;
	transition: all 0.3s;
}

.hide .route:hover {
	padding-right: 0.25rem;
	padding-left: 0.75rem;
	transition: all 0.3s;
}

.route div {
	color: white;
	padding: 0 0.5rem 0 1rem;
	white-space: nowrap;
	visibility: visible;
	z-index: 1;
	transition: all 0.3s;
}

.hide .route div {
	visibility: hidden;
	z-index: -1;
	transition: all 0.3s;
}

.selected {
	background-color: rgb(110, 110, 110);
}
