.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-style: solid;
}

.vert-line {
	height: 25%;
}

.hor-line {
	width: 50%;
}

.triangle {
	position: relative;
	height: 25%;
	width: 50%;
}
