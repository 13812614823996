.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
}

.hor-line {
	width: 50%;
}

.main {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	transform-origin: bottom right;
}

.vert-line {
	height: 100%;
}

.rect {
	height: 30%;
	border-style: solid;
	border-right-style: unset;
}
