.my-count {
	position: absolute;
	width: 20px;
	height: 20px;
	cursor: pointer;
	border-radius: 100%;
	font-size: 0.7rem;
	text-align: center;
	background: #ff6258;
	color: #ffffff;
}

.bg-primary {
	background: rgb(33, 150, 243) !important
}

.primary {
	color: rgb(33, 150, 243) !important
}

.btn-primary {
	background: rgb(33, 150, 243) !important;
	color: white !important;
	padding: 0.5rem 1.5rem !important;
}

.btn-primary:hover {
	background: rgb(33, 120, 213) !important
}

.bg-danger {
	background: rgb(255, 98, 88) !important
}

.danger {
	color: rgb(255, 98, 88) !important
}

.btn-danger {
	background: rgb(255, 98, 88) !important;
	color: white !important;
	padding: 0.5rem 1.5rem !important;
}

.btn-danger:hover {
	background: rgb(255, 68, 48) !important
}

.bg-success {
	background: rgb(25, 216, 149) !important
}

.success {
	color: rgb(25, 216, 149) !important
}

.btn-success {
	background: rgb(25, 216, 149) !important;
	color: white !important;
	padding: 0.5rem 1.5rem !important;
}

.btn-success:hover {
	background: rgb(25, 190, 120) !important
}

.bg-dark {
	background: black !important
}

.dark {
	color: black !important
}

.btn-dark {
	background: black !important;
	color: white !important;
	padding: 0.5rem 1.5rem !important;
}

.btn-dark:hover {
	background: rgb(30, 30, 30) !important
}

.bg-red {
	background: red !important
}

.red {
	color: red !important
}


.switch, .fake-switch {
	position: absolute;
	cursor: pointer;
	z-index: 3;
}

.switch-warn, .switch-err {
	position: absolute;
	display: block;
	z-index: 5;
}

.switch-name, .switch-name-only-scheme, .image-line-name, .text, .agr-text {
	position: absolute;
	font-size: 0.8rem;
	font-family: Trebuchet MS, sans-serif;
	z-index: 2;
	white-space: nowrap;
}

.switch-name, .switch-name-only-scheme, .image-line-name, .agr-text {
	padding: 0.2rem;
	background: rgba(169, 169, 169, 0.5);
	border-radius: 5px;
	border: rgba(169, 169, 169, 0.5) solid 0.5px;

}

.agr-text {
	font-size: 1.25rem;
}

.vert-name.switch-name, .vert-name.switch-name-only-scheme {
	right: calc(100% + 7px);
	top: 0;
}

.hor-name ~ .switch-name, .hor-name ~ .switch-name-only-scheme {
	bottom: calc(100% + 7px);
	left: 0;
	right: auto;
	top: auto;
}

.switch-name-only-scheme {
	color: #132fb9 !important;
}

.image-line-name {
	background: none !important;
	border-width: 0 !important;
}

.hide-warn {
	display: none;
}

.switch_img {
	display: none;
	width: 100%;
	z-index: 2 !important;
}

.s-active {
	display: block;
}

.line, .triangle {
	position: absolute;
	z-index: 2;
}

.line-v {
	border-left: 4px rgb(22, 255, 22) solid;
}

.line-h {
	border-top: 4px rgb(22, 255, 22) solid;
}

.line-off {
	border-color: rgb(22, 255, 22);
}

.line-on {
	border-color: rgb(255, 32, 32);
}

.line-on-blue {
	border-color: rgb(50, 150, 255);
}

.line-repair {
	border-color: rgb(0, 0, 0);
}

.triangle {
	overflow: hidden;
	border-top: black solid 2px
}

.triangle-left-side {
	position: absolute;
	left: 0;
	height: 100%;
	border-left: black solid 2px;
	transform-origin: 0 0;
}

.triangle-right-side {
	position: absolute;
	right: 0;
	height: 100%;
	border-right: black solid 2px;
	transform-origin: 0 0;
}

.trng-off, .trng-off > div {
	border-color: rgb(22, 255, 22) !important;
}

.trng-on, .trng-on > div {
	border-color: rgb(255, 32, 32) !important;
}

.trng-on-blue, .trng-on-blue > div {
	border-color: rgb(50, 150, 255) !important;
}

.trng-repair, .trng-repair > div {
	border-color: black !important;
}

.access-btn, .edit-btn {
	color: white !important;
}

.state-online, .state-offline {
	padding: 0.1rem 0.3rem;
	border-radius: 5px;
	font-size: 0.8rem;
}

.state-online {
	color: black;
}

.state-offline {
	color: white;
}

.MuiDataGrid-root .MuiDataGrid-cell {
	white-space: normal !important;
	word-wrap: break-word !important;
}
.MuiDataGrid-columnHeader:hover div.MuiDataGrid-columnHeaderTitle {
	white-space: unset !important;
}

.MuiOutlinedInput-root > fieldset {
	border-color: rgba(0, 0, 0, 0.55) !important;
}

.MuiFormLabel-root {
	color: rgba(0, 0, 0, 1) !important;
}

@media (max-width: 991px) {
	.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
		line-height: 20px;
		white-space: normal !important;
		word-wrap: break-word !important;
	}
	.MuiDataGrid-columnHeader--sorted div.MuiDataGrid-columnHeaderTitle {
		white-space: unset !important;
	}
}

.MuiTooltip-tooltip {
	font-size: 1rem !important;
}

.scada-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: nowrap;
	overflow: visible;
	/*background: white; !* develop *!*/
}

.scada-cont-hor {
	flex-direction: row;
	justify-content: flex-start;
}
