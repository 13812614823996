.container {
	display: none;
	position: absolute;
	left: 0;
	bottom: 0;
	align-self: flex-start;
	padding: 0.3rem 0.5rem;
	margin: 0 0 0.7rem 0.5rem;
	z-index: 5;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}

.wrapper {
	font-size: 1rem;
	white-space: nowrap;
	color: white;
	line-height: 1.5;
}

@media (min-width: 900px) {
	.container {
		display: block;
	}
}
