.container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0.25rem 0;
}

.wrapper {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	font-size: 1.2rem;
}

.wrapper > div {
	display: flex;
	justify-content: center;
	align-items: center;
	border: black solid 1px;
	min-height: 2.5rem;
	width: 3rem;
	background-color: rgb(235, 235, 235);
}

.not-active > div, .not-active > .warn {
	z-index: 5 !important;
	background: rgba(0, 0, 0, 0.3) !important;
	cursor: default !important;
	color: black !important;
}

.warn {
	cursor: pointer;
	margin-left: -17px;
	margin-top: 5px;
	display: block;
}

.warn span {
	height: 15px;
	width: 15px;
	font-size: 0.77rem;
	margin-left: 2px;
}

.on, .off, .warning, .error {
	padding: 0 0.2rem;
}

.on {
	color: #e1534c;
}

.off {
	color: rgb(64, 189, 64);
}

.warning {
	background: #1976d2;
	color: #1976d2;
}

.error {
	background: #ff6258;
	color: #ff6258
}

.error > h6, .warning > h6 {
	color: white;
}

.logs {
	height: 100%;
	overflow-y: auto;
}
