.on {color: #e1534c !important;}

.off {color: rgb(64, 189, 64) !important;}

.warning {
	background-color: #1976d2 !important;
	color: white !important;
}

.error {
	background-color: #ff6258 !important;
	color: white !important;
}
