.container {
    min-height: 100vh;
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(240, 240, 240);
}

.wrapper {
    width: 32rem;
    background: white;
    border: 1px solid rgb(230, 230, 230);
    padding: 2.8rem 3.2rem;
	margin-bottom: 5rem;
}

.card {
    display: flex;
    flex-direction: column;
}

.button {
    margin-top: 1.7rem !important;
}

.error {
    color: red !important;
    margin: 0.5rem 0 1rem !important;
}

.error2 {
	color: red !important;
	margin-top: 0.5rem !important;
}

