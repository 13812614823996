.container {
	display: flex;
	justify-content: center;
	width: 100%;
}

.wrapper {
	margin: 1.5rem;
	padding: 2rem;
	width: 30rem;
	border-radius: 15px;
	background: #f9f9f9;
	display: flex;
	flex-direction: column;
}

.pass{
	margin-top: 20px;
}

@media (max-width: 991px) {
	.container {
		padding: 1rem 0.5rem;
	}
	.wrapper {
		margin: 1.5rem;
		padding: 2rem;
		width: auto;
		border-radius: 15px;
		background: #eaeaea;
		display: flex;
		flex-direction: column;
	}
}
