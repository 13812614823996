.container {
	display: flex;
	flex: 0 0 4.6rem;
	padding: 0.8rem 0.8rem;
	min-height: 5rem;
}

.title {
	display: flex;
	padding: 1rem;
	align-items: center;
	flex-grow: 1;
	cursor: pointer;
}

.warning {
	width: 20%;
}
