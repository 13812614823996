.container {
	display: none;
	padding: 0 1rem;
	height: 100%;
	align-items: center;
}

.menu {
	display: block;
}

@media (min-width: 1000px) {
	.container {
		display: flex;
	}

	.menu {
		display: none;
	}
}
