.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
}

.hor-line {
	width: 50%;
}

.vert-line {
	height: 100%;
	transform-origin: bottom right;
}

.horizontal {
	transform: scale(1, -1) rotate(-90deg);
}
