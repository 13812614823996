.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	overflow: auto;
}

.wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 120rem;
	margin-bottom: 0.5rem;
}

.error {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
}
