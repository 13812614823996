.container {
	position: relative;
}

.on-click {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
