html, body {
	padding: 0;
	margin: 0;
	width:100%;
	overflow: hidden;
}

.container {
	position: relative;
	display: flex;
	min-height: 100vh;
	height: 1px;
	overflow: hidden;
}

.wrapper {
	display: flex;
	flex-direction: column;
	flex: 1 0 100px;
	min-height: 100vh;
	height: 1px;
	overflow: hidden;
}

@media (max-width: 991px) {
	html, body {
		overflow: auto;
	}
}

.content {
	flex: 1 0 100px;
	height: calc(100% - 2.2rem);
	width: 100%;
	overflow: auto;
}
