.container {
	margin: 0 0.25rem;
	padding: 0.2rem 0.4rem;
	border: 1px solid grey;
	border-radius: 0.1875rem;
	transition: all 0.1s;
}

.container:hover, .active {
	cursor: pointer;
	color: white !important;
	box-shadow: 3px 1px 3px 3px rgba(0, 0, 0, 0.2);
	transition: all 0.1s;
}

.container:not(.alarm):hover, .active {
	background: #1c45ef !important;
}

.alarm {
	color: white !important;
	background: rgb(255, 32, 32) !important;
}
