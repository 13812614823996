.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
}

.circle {
	position: absolute;
	aspect-ratio: 1;
	border-radius: 50%;
	border-style: solid;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bottom-circle {
	bottom: 0;
	align-items: flex-end;
}

.stick, .left-stick, .right-stick {
	height: 30%;
}

.stick {
	position: relative;
}

.bottom-circle .stick {
	margin-bottom: 5%;
}

.left-stick {
	transform-origin: top right;
	transform: rotate(135deg);
}

.right-stick {
	transform-origin: top left;
	transform: rotate(-135deg);
}
