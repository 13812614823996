.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.up {
	width: 100%;
}

.center {
	width: 73%;
}

.down {
	width: 40%;
}
