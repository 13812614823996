.container, .container2 {
  display: flex;
  justify-content: center;
  padding: 0.7rem 0.9rem;
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
  flex-grow: 1;
  margin-left: 0.5rem;
}

.container:first-child {
  margin-left: 0;
}
