.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.up {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.down {
	width: 100%;
}

.side {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	overflow: visible;
	width: 1px;
}

.vertical {
	height: 59.7%;
	transform-origin: bottom right;
}

.horizontal {
	width: 1000%;
}
