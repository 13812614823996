.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.image {
	width: 100%;
	display: flex;
	justify-content: center;
}

.image img {
	height: 200px;
}

.title {
	font-size: 1.2rem !important;
	margin-bottom: 0.8rem !important;
}

.title2 {
	font-size: 1.2rem !important;
	margin-top: 1rem !important;
}
