.wrapper {
	position: relative;
	max-height: 100%;
	margin: auto;
	/*background: lightgrey; !* develop *!*/
}

.text {
	margin-top: 1rem !important;
}

.select {
	width: 100%;
	padding: 0.4375rem 0.75rem;
	outline: 1px solid #dee2e6;
	border-radius: 0.2rem;
	font-family: "Roboto", sans-serif;
	font-weight: initial;
	margin-bottom: 1rem;
}

.select option {
	font-family: "Roboto", sans-serif;
	font-weight: initial;
}

.desc {
	padding: 0.4375rem 0.75rem;
	outline: 1px solid #dee2e6;
	border-radius: 0.2rem;
	width: 95%;
	height: 5rem;
	resize: none;
}

.warning {
	display: flex;
}

.warning > h6 {
	margin-left: 0.35rem;
}

.repair {
	display: flex;
}
