.container {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 50;
	min-height: 100vh;
	width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(240, 240, 240);
}

.wrapper {
	width: 32rem;
    background: white;
    border: 1px solid rgb(230, 230, 230);
    padding: 2.8rem 3.2rem;
	margin-bottom: 5rem;
}

.card {
    display: flex;
    flex-direction: column;
}


.button {
    margin-top: 1.7rem !important;
}
