.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
}

.extreme {
	position: relative;
	height: 19%;
	width: 60%;
	overflow: hidden;
}

.center {
	display: flex;
	flex-grow: 1;
	width: 100%;
}

.agr-space {
	margin-right: 17% !important;
}
