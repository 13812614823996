.content {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	user-select: none;
}

.scada-container {
	padding-bottom: 0.5rem;
	width: 100%;
	overflow: auto;
}

.loading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 5;
	background: rgba(0, 0, 0, .5);
	padding: 0 0 0 70px;
	margin: 0;
}

.loading-text {
	font: normal 14px/20px Arial, sans-serif;
	margin-bottom: -100px;
	background: #fff;
	color: #000;
	z-index: 11;
	padding: 10px;
	border-radius: 3px;
	box-shadow: 0 4px 30px 0 rgba(0, 0, 0, .5);
}

.hide {
	display: none;
}

@media (max-height: 800px) {
	.iconbar-container {
		/*box-shadow: 3px 3px 5px 5px rgba(0, 0, 0, 0.1);*/
	}
}
