.container {
	display: flex;
	justify-content: space-between;
	height: 100%;
	width: 100%;
}

.side {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: visible;
	height: 100%;
	width: 1px;
}

.stick {
	height: 100%;
}
