.container {
	display: flex;
	align-items: center;
	color: #1c45ef;
	font-size: 1.2rem;
	font-weight: bold;
	font-family: Orbitron, serif;
	letter-spacing: 2px;
	min-width: 8rem;
}

.date {
	display: none;
	margin-right: 1.3rem;
}

@media (min-width: 1300px) {
	.date {
		display: block;
	}

	.container {
		min-width: 20rem;
	}
}

.load {
	padding: 0.2rem 0
}
