.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
}

.top {
	height: 12%;
}

.vert-line {
	flex-grow: 1;
	display: flex;
	align-items: center;
	transform-origin: top left;
	transform: skewX(28deg);
}

.hor-line {
	width: 100%;
	border-top-style: solid;
	border-bottom-style: solid;
}

.top, .bottom, .vert-line {
	border-left-style: solid;
	border-right-style: solid;
}
