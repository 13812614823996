.inActive {
	background: inherit;
	color: black !important;
}

.active {
	background: linear-gradient(to left, #4c5cec, #1c45ef) !important;
	color: white !important;
}

.alarm {
	color: white !important;
	background: rgb(255, 32, 32) !important;
}
