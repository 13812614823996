.container {
	background-color: inherit;
	/*flex: 0 0 49%;*/
	min-width: 30rem;
	max-width: 30rem;
	min-height: 13.5rem;
	max-height: 15rem;
	margin: 1rem;
	position: relative;
}

@media (max-width: 40rem) {
	.container {
		min-width: 25rem;
	}
}

@media (max-width: 30rem) {
	.container {
		min-width: 20rem;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: rgb(240, 240, 240);
	height: 100%;
	border-radius: 5px;
	border: 0.5px solid rgb(200, 200, 200);
	transition: all 0.2s;
}

.wrapper:hover {
	box-shadow: 5px 5px 4px 5px rgba(0, 0, 0, 0.1);
	border: 0.5px solid white;
	background-color: white;
	transition: all 0.2s;
}

.indicators {
	display: flex;
	justify-content: space-between;
	cursor: default;
}

.offline {
	z-index: 10;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 101%;
	background: rgba(0, 0, 0, 0.62);
	border-radius: 5px;
}
